<script setup lang="ts">
const auth = useAuth();

onBeforeMount(async () => {
  await auth.optimisticAuth();
  if (auth.isLoggedIn) {
    await navigateTo(DEFAULT_HOME);
  } else {
    await navigateTo('/welcome');
  }
});

definePageMeta({
  middleware: ['public-only'],
  noPWA: true,
  layout: 'auth',
});
</script>

<template>
  <TMain class="p-main" :header-logo="true" :back="false">
    <template #body>
      <div class="p-main-inner">
        <ProgressSpinner class="custom-spinner" />
      </div>
    </template>
  </TMain>
</template>

<style lang="scss">
.p-main {
  .p-main-inner {
    height: 70vh;
    display: flex;
    align-items: center;
  }
}
</style>
